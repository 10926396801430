import className from "classnames/bind";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import {
    ClassicEditor,
    AccessibilityHelp,
    Autoformat,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Code,
    Essentials,
    FindAndReplace,
    Heading,
    Highlight,
    HorizontalLine,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    SelectAll,
    SimpleUploadAdapter,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    Undo,
} from "ckeditor5";

import { createMarkup } from "../../utils/makup";

import styles from "./CreateBlog.module.scss";
import { useNavigate, useParams } from "react-router-dom";

const cx = className.bind(styles);

function CreateBlog() {
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const [token, setToken] = useState(null);

    const [mode, setMode] = useState("create");

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const param = useParams();

    const { myPosts } = useSelector((state) => state.posts);
    const [postEdit, setPostEdit] = useState(null);

    useEffect(() => {
        if (myPosts.data) {
            setPostEdit(
                myPosts.data?.find((post) => {
                    return post.id.toString() === param.id;
                })
            );
        } else {
            if (param.id) {
                dispatch({
                    type: "FETCH_POST_REQUESTED",
                    payload: {
                        path: `/user/posts/${param.id}`,
                    },
                });
            }
        }
    }, [myPosts, param]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        token && setToken(token);
    }, []);

    useEffect(() => {
        if (param.edit) {
            setIsLoading(true);
            setMode("edit");

            if (postEdit) {
                setTitle(postEdit.title);
                setIsLoading(false);
            }
        }
    }, [param, postEdit]);

    useEffect(() => {
        setIsLayoutReady(true);
        return () => setIsLayoutReady(false);
    }, []);

    const editorConfig = {
        toolbar: {
            items: [
                "undo",
                "redo",
                "|",
                "heading",
                "|",
                "bold",
                "italic",
                "underline",
                "|",
                "link",
                "insertImage",
                "insertTable",
                "highlight",
                "blockQuote",
                "|",
                "bulletedList",
                "numberedList",
                "todoList",
                "outdent",
                "indent",
            ],
            shouldNotGroupWhenFull: true,
        },
        plugins: [
            AccessibilityHelp,
            Autoformat,
            AutoImage,
            AutoLink,
            Autosave,
            BalloonToolbar,
            BlockQuote,
            Bold,
            Code,
            Essentials,
            FindAndReplace,
            Heading,
            Highlight,
            HorizontalLine,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsert,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            MediaEmbed,
            Paragraph,
            SelectAll,
            SimpleUploadAdapter,
            SpecialCharacters,
            SpecialCharactersArrows,
            SpecialCharactersCurrency,
            SpecialCharactersEssentials,
            SpecialCharactersLatin,
            SpecialCharactersMathematical,
            SpecialCharactersText,
            Strikethrough,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            TodoList,
            Underline,
            Undo,
        ],
        balloonToolbar: [
            "bold",
            "italic",
            "|",
            "link",
            "insertImage",
            "|",
            "bulletedList",
            "numberedList",
        ],
        simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: `${process.env.REACT_APP_BASE_URL_API}/image/store`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
        heading: {
            options: [
                {
                    model: "paragraph",
                    title: "Paragraph",
                    class: "ck-heading_paragraph",
                },
                {
                    model: "heading1",
                    view: "h1",
                    title: "Heading 1",
                    class: "ck-heading_heading1",
                },
                {
                    model: "heading2",
                    view: "h2",
                    title: "Heading 2",
                    class: "ck-heading_heading2",
                },
                {
                    model: "heading3",
                    view: "h3",
                    title: "Heading 3",
                    class: "ck-heading_heading3",
                },
                {
                    model: "heading4",
                    view: "h4",
                    title: "Heading 4",
                    class: "ck-heading_heading4",
                },
                {
                    model: "heading5",
                    view: "h5",
                    title: "Heading 5",
                    class: "ck-heading_heading5",
                },
                {
                    model: "heading6",
                    view: "h6",
                    title: "Heading 6",
                    class: "ck-heading_heading6",
                },
            ],
        },
        image: {
            toolbar: [
                "toggleImageCaption",
                "imageTextAlternative",
                "|",
                "imageStyle:inline",
                "imageStyle:wrapText",
                "imageStyle:breakText",
                "|",
                "resizeImage",
            ],
        },
        initialData: "",
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: "https://",
            decorators: {
                toggleDownloadable: {
                    mode: "manual",
                    label: "Downloadable",
                    attributes: {
                        download: "file",
                    },
                },
            },
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true,
            },
        },

        placeholder: "Nội dung viết ở đây",
        table: {
            contentToolbar: [
                "tableColumn",
                "tableRow",
                "mergeTableCells",
                "tableProperties",
                "tableCellProperties",
            ],
        },
    };

    const handleSubmit = () => {
        if (title === "" || content === "") return;

        if (mode === "create")
            dispatch({
                type: "CREATE_POST_REQUESTED",
                payload: {
                    path: "/user/posts",
                    data: {
                        title,
                        content,
                    },
                },
            });

        if (mode === "edit")
            dispatch({
                type: "UPDATE_POST_REQUESTED",
                payload: {
                    path: `/user/posts/update/${postEdit.id}`,
                    data: {
                        title,
                        content,
                    },
                },
            });

        navigate("/my-posts");
    };

    const handleOnReadyEditor = (editor) => {
        if (postEdit) {
            editor.setData(postEdit.content);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <div className={cx("container-fluid", "mt-20")}>
            <div className={cx("header")}>
                <input
                    className={cx("input-title")}
                    type="text"
                    placeholder="Title ..."
                    value={title}
                    required
                    onChange={(e) => setTitle(e.target.value)}
                />
                <button className={cx("btn")} onClick={handleSubmit}>
                    Xuất bản
                </button>
            </div>
            <div className={cx("content", "ck-content")}>
                <div className={cx("editor")}>
                    <div className={cx("main-container")}>
                        <div
                            className="editor-container editor-container_classic-editor"
                            ref={editorContainerRef}
                        >
                            <div className="editor-container__editor">
                                <div ref={editorRef}>
                                    {isLayoutReady && (
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={editorConfig}
                                            onChange={(event, editor) => {
                                                setContent(editor.getData());
                                            }}
                                            onReady={handleOnReadyEditor}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("show-content")}>
                        <div
                            className="ck-content "
                            dangerouslySetInnerHTML={createMarkup(content)}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateBlog;
