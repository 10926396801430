import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as httpRequest from "../../utils/httpRequest";
import {
    loginFail,
    loginSuccess,
    getUserSuccess,
    registerFail,
    registerSuccess,
    logoutUserSuccess,
    logoutUserFail,
} from "../authSlice";
import {
    createPostFail,
    createPostSuccess,
    deletePostsFail,
    deletePostsSuccess,
    fetchPostFail,
    fetchPostsFail,
    fetchPostsSuccess,
    fetchPostSuccess,
    updatePostFail,
    updatePostSuccess,
} from "../postSlice";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* userForgotPassword(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        console.log(res);
    } catch (e) {
        console.log(e);
    }
}
function* userResetPassword(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        console.log(res);
    } catch (e) {
        console.log(e);
    }
}

function* userChangePassword(action) {
    try {
        console.log(123);
        const res = yield call(httpRequest.post, action.payload);
        console.log(res);
    } catch (e) {
        console.log(e);
    }
}

function* logoutUser(action) {
    try {
        const res = yield call(httpRequest.get, action.payload);
        localStorage.removeItem("token");

        yield put(logoutUserSuccess(res.message));
    } catch (e) {
        yield put(logoutUserFail(e.message));
    }
}

function* fetchUser(action) {
    try {
        const res = yield call(httpRequest.get, action.payload);

        yield put(getUserSuccess(res.user));
    } catch (e) {
        yield put(loginFail(e.message));
        localStorage.removeItem("token");
    }
}
function* fetchUserLogin(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        localStorage.setItem("token", res.user.token);
        yield put(loginSuccess(res.user));
    } catch (e) {
        yield put(loginFail(e.response.data.message));
    }
}

function* fetchUserRegister(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        localStorage.setItem("token", res.user.token);
        yield put(registerSuccess(res.user));
    } catch (e) {
        yield put(registerFail(e.message));
    }
}

function* fetchPost(action) {
    try {
        const res = yield call(httpRequest.get, action.payload);
        yield put(fetchPostSuccess(res));
    } catch (e) {
        yield put(fetchPostFail(e.message));
    }
}

function* createPost(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        yield put(createPostSuccess(res));
    } catch (e) {
        yield put(createPostFail(e.message));
    }
}

function* updatePost(action) {
    try {
        const res = yield call(httpRequest.put, action.payload);
        yield put(updatePostSuccess(res));
    } catch (e) {
        yield put(updatePostFail(e.message));
    }
}

function* deletePosts(action) {
    try {
        const res = yield call(httpRequest.remove, action.payload);
        yield put(deletePostsSuccess(res));
    } catch (e) {
        yield put(deletePostsFail(e.message));
    }
}

function* fetchPosts(action) {
    try {
        const res = yield call(httpRequest.get, action.payload);
        yield put(fetchPostsSuccess(res));
    } catch (e) {
        yield put(fetchPostsFail(e.message));
    }
}

function* uploadAvatar(action) {
    try {
        const res = yield call(httpRequest.post, action.payload);
        yield put(getUserSuccess(res.user));
    } catch (e) {
        yield put(logoutUserFail(e.message));
    }
}

function* mySaga() {
    yield takeEvery("LOGOUT_USER", logoutUser);
    yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
    yield takeLatest("USER_LOGIN_REQUESTED", fetchUserLogin);
    yield takeLatest("USER_REGISTER_REQUESTED", fetchUserRegister);
    yield takeLatest("USER_FORGOT_PASSWORD_REQUESTED", userForgotPassword);
    yield takeLatest("USER_RESET_PASSWORD_REQUESTED", userResetPassword);
    yield takeLatest("USER_CHANGE_PASSWORD_REQUESTED", userChangePassword);

    yield takeLatest("CREATE_POST_REQUESTED", createPost);
    yield takeLatest("UPDATE_POST_REQUESTED", updatePost);
    yield takeLatest("FETCH_POST_REQUESTED", fetchPost);
    yield takeLatest("FETCH_POSTS_REQUESTED", fetchPosts);
    yield takeLatest("DELETE_POST_REQUESTED", deletePosts);

    yield takeLatest("UPLOAD_AVATAR_REQUESTED", uploadAvatar);
}

export default mySaga;
